
// system wide H tags

export default {
    name: 'Tag',
    model: {
        name: '',
        uuid: '',
        text: '',
        iconic: {
          uuid: '',
          url: ''
        }
      }
}