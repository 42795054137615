<template>
  <section>
    <div>
      tagoptions <b>{{ kip }}</b>
    </div>
    xxx: {{ treenodes }}
  </section>
</template>

<script>
import { API } from "../tic";
import { onMounted, reactive, ref } from "@vue/composition-api";

export default {
  name: "hiTags",

  setup() {
    //treenodes: reactive({ foo: "bar" });
    let treenodes = reactive({ foo: "bar" });
    const kip = ref("test");

    onMounted(() => {
      console.log("MOUNTED!");
      console.log("call load!");
      load();
    });

    function load() {
      let vm = this;
      console.log("load-data");
      API.get("/tags").then((r) => {
        console.log("tags received: ", r.data.data);
        console.log("treenodes now: ", treenodes);
        Object.assign(treenodes, r.data.data);
        console.log("treenodes now: ", treenodes);
        // kip.value = "ei";
      });
    }

    load();
    console.log("hitags setup");
    //console.log("m:", treenodes);
    return {
      treenodes,
      kip,
    };
  },
};
</script>

